
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@babel/polyfill'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require.context('../images', true)

import '../css/application.scss';
import "@fortawesome/fontawesome-free/css/all.css";
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

let hostname = window.location.hostname

if (/lexus/.test(hostname)) {
  import('../css/lexus.scss').then();
} else {
  import('../css/toyota.scss').then();
}

// Core - these two are required :-)
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import 'tinymce/skins/content/default/content.css'

// Plugins
import 'tinymce/plugins/paste/plugin'
import 'tinymce/plugins/link/plugin'
import 'tinymce/plugins/lists';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function onLoad() {

  let menu = document.getElementById('menu');
  let menuBtn = document.getElementById('menu-btn');
  let menuCloseBtn = document.getElementById('menu-close-btn');

  if (menuCloseBtn) {
    menuCloseBtn.addEventListener('click', function() {
      menu.classList.add('hidden')
    })
  }

  if (menuBtn) {
    menuBtn.addEventListener('click', function() {
      menu.classList.remove('hidden')
    })
  }

  const policies = document.getElementsByClassName('policy');
  const whitePills = document.getElementsByClassName('white-pill');

  for(const policy of policies) {
    const button = policy.children[0];
    const info = policy.children[1];
    const textShowMore = button.children[1].children[0];
    const textHide = button.children[1].children[1];
    const arrow = button.children[1].children[2];
    const children = policy.children[1].querySelectorAll('.white-pill');
    const occasionalDrivers = policy.children[1].querySelector('.occasional-drivers');
    const usualDriver = policy.children[1].querySelector('.usual-driver');
    const occasionalDriversBtn = policy.children[1].querySelector('.occasional-drivers-btn');
    const usualDriverBtn = policy.children[1].querySelector('.usual-driver-btn');
    let maxHeight = 0;

    button.addEventListener('click', function() {
      for (const child of children) {
        maxHeight += child.nextElementSibling.scrollHeight;
      }

      maxHeight += usualDriver.scrollHeight;
      maxHeight += occasionalDrivers.scrollHeight;

      if (info.classList.contains('max-h-0')) {
        info.classList.add('active');
        info.style.maxHeight = maxHeight + "px";
      } else {
        info.classList.remove('active');
        info.style.maxHeight = "0px";
      }

      info.classList.toggle('max-h-0');
      arrow.classList.toggle('rotate-180');
      textShowMore.classList.toggle('lg:block');
      textHide.classList.toggle('lg:block');
    });

    if (policies.length == 1) {
      info.classList.toggle('max-h-0');
      arrow.classList.toggle('rotate-180');
      textShowMore.classList.toggle('lg:block');
      textHide.classList.toggle('lg:block');
    }

    occasionalDriversBtn.addEventListener('click', function() {
      usualDriver.classList.add('hidden');
      usualDriver.classList.remove('lg:flex');
      occasionalDrivers.classList.remove('hidden');
      occasionalDrivers.classList.add('lg:flex');
      occasionalDrivers.classList.add('lg:flex-wrap');
      usualDriverBtn.classList.add('bg-gray-toyota-2');
      usualDriverBtn.classList.add('text-gray-toyota');
      usualDriverBtn.classList.remove('bg-gray-toyota');
      usualDriverBtn.classList.remove('text-white');
      occasionalDriversBtn.classList.add('bg-gray-toyota');
      occasionalDriversBtn.classList.add('text-white');
      occasionalDriversBtn.classList.remove('bg-gray-toyota-2');
      occasionalDriversBtn.classList.remove('text-gray-toyota');
    });

    usualDriverBtn.addEventListener('click', function() {
      usualDriver.classList.remove('hidden');
      usualDriver.classList.add('lg:flex');
      usualDriver.classList.add('lg:flex-wrap');
      occasionalDrivers.classList.add('hidden');
      occasionalDrivers.classList.remove('lg:grid');
      usualDriverBtn.classList.add('bg-gray-toyota');
      usualDriverBtn.classList.add('text-white');
      usualDriverBtn.classList.remove('bg-gray-toyota-2');
      usualDriverBtn.classList.remove('text-gray-toyota');
      occasionalDriversBtn.classList.add('text-gray-toyota');
      occasionalDriversBtn.classList.add('bg-gray-toyota-2');
      occasionalDriversBtn.classList.remove('text-white');
      occasionalDriversBtn.classList.remove('bg-gray-toyota');
    });
  }

  for(const whitePill of whitePills) {
    const info = whitePill.nextElementSibling;
    const arrow = whitePill.children[1].children[0];
    const occasionalDrivers = whitePill.nextElementSibling.querySelector('.occasional-drivers');
    const usualDriver = whitePill.nextElementSibling.querySelector('.usual-driver');

    whitePill.addEventListener('click', function() {
      if (info.classList.contains('max-h-0')) {
        let maxHeight = info.scrollHeight;

        if (occasionalDrivers) {
          maxHeight += occasionalDrivers.scrollHeight;
        }

        if (usualDriver) {
          maxHeight += usualDriver.scrollHeight;
        }

        info.style.maxHeight = maxHeight + "px";
      } else {
        info.style.maxHeight = "0px";
      }

      info.classList.toggle('max-h-0');
      arrow.classList.toggle('rotate-180');
    });
  }

  let passwordModal = document.getElementById('password-modal');
  let passwordModalBtn = document.getElementById('password-modal-btn');
  let passwordModalCloseBtn = document.getElementById('password-modal-close-btn');

  if (passwordModalCloseBtn) {
    passwordModalCloseBtn.addEventListener('click', function() {
      passwordModal.classList.add('hidden')
    })
  }

  if (passwordModalBtn) {
    passwordModalBtn.addEventListener('click', function() {
      passwordModal.classList.remove('hidden')
    })
  }

  let resendInvitationModal = document.getElementById('resend-invitation-modal');
  let resendInvitationModalBtn = document.getElementById('resend-invitation-modal-btn');
  let resendInvitationModalCloseBtn = document.getElementById('resend-invitation-modal-close-btn');

  if (resendInvitationModalCloseBtn) {
    resendInvitationModalCloseBtn.addEventListener('click', function() {
      resendInvitationModal.classList.add('hidden')
    })
  }

  if (resendInvitationModalBtn) {
    resendInvitationModalBtn.addEventListener('click', function() {
      resendInvitationModal.classList.remove('hidden')
    })
  }

  function showNotificationSubject(showSubject) {
    const sendNotificationSubjectContainer = document.getElementById('notification-subject-container');
    const sendNotificationSubject = document.getElementById('promotion_notification_subject');

    if (showSubject) {
      sendNotificationSubjectContainer.classList.remove('hidden')
      sendNotificationSubject.required = true;
    } else {
      sendNotificationSubjectContainer.classList.add('hidden')
      sendNotificationSubject.required = false;
    }
  }

  const sendNotificationCheckbox = document.getElementById('promotion_send_notification');

  if (sendNotificationCheckbox) {
    showNotificationSubject(sendNotificationCheckbox.checked)

    sendNotificationCheckbox.addEventListener('click', function() {
      showNotificationSubject(sendNotificationCheckbox.checked);
    })
  }

  function toggleStandalonePromotion(standalone) {
    const standaloneFieldsContainer = document.getElementById('standalone-fields-container');
    const genericFieldsContainer = document.getElementById('generic-fields-container');
    const templateField = document.getElementById('promotion_template');
    const descriptionField = document.getElementById('promotion_description');

    if (standalone) {
      standaloneFieldsContainer.classList.remove('hidden')
      genericFieldsContainer.classList.add('hidden')
      templateField.required = true;
    } else {
      genericFieldsContainer.classList.remove('hidden')
      standaloneFieldsContainer.classList.add('hidden')
      templateField.required = false;
    }
  }

  const standaloneRadioButton = document.getElementById('promotion_standalone_true');
  const genericRadioButton = document.getElementById('promotion_standalone_false');

  if (standaloneRadioButton && genericRadioButton) {
    toggleStandalonePromotion(standaloneRadioButton.checked)

    standaloneRadioButton.addEventListener('click', function() {
      toggleStandalonePromotion(standaloneRadioButton.checked);
    })

    genericRadioButton.addEventListener('click', function() {
      toggleStandalonePromotion(standaloneRadioButton.checked);
    })
  }

  tinymce.init({
    selector: '#promotion_description',
    plugins: ['paste', 'link', 'lists'],
    menubar: false,
    toolbar: 'cut copy paste | undo redo | bold italic underline strikethrough | bullist | link',
    skin: false,
    height : 300,
    content_css : "/js/tinymce/skins/content/default/content.css",
  })

  const activeByDefault = document.getElementById('promotion_active_by_default');

  if (activeByDefault) {
    toggleLegalConditions(activeByDefault.checked)

    activeByDefault.addEventListener('click', function() {
      toggleLegalConditions(activeByDefault.checked);
    })
  }

  function toggleLegalConditions(activeByDefalt) {
    const legalContidionsField = document.getElementById('legal-conditions-container');

    if (activeByDefalt) {
      legalContidionsField.classList.add('hidden')
    } else {
      legalContidionsField.classList.remove('hidden')
    }
  }

  tinymce.init({
    selector: '#promotion_legal_conditions',
    plugins: ['paste', 'link', 'lists'],
    menubar: false,
    toolbar: 'cut copy paste | undo redo | bold italic underline strikethrough | bullist | link',
    skin: false,
    height : 500,
    content_css : "/js/tinymce/skins/content/default/content.css",
  })
};

document.addEventListener('turbolinks:load', onLoad);

const isBabel = !(class {}.toString().indexOf('class ') === 0);